<template>
  <div class="warp">
    <div class="left">
      <p class="title">
        <el-button class="add" type="primary" icon="el-icon-circle-plus" size="mini" v-if="isAuth('platform-config:fixedintegraltype:save')" @click="openType()" typeShow=true>新增</el-button>
      </p>
      <ul>
        <li :class="{'active':active===-1}" @click="activeType(-1)">全部</li>
        <li :class="{'active':active===item.id}" @click="activeType(item.id)" v-for="(item,i) in typeList" :key="'typeList'+i">
          {{item.name}}
          <span>

            <img @click.stop="openType(item)" v-if="isAuth('platform-config:fixedintegraltype:update')" src="@/assets/img/icon/icon_edit.png">
            <img @click.stop="delType(item)" v-if="isAuth('platform-config:fixedintegraltype:delete')" class="ml10" style="margin-left:5px" src="@/assets/img/icon/icon_delete.png">
          </span>
        </li>
      </ul>
    </div>
    <div class="right">
      <jf-layout>
        <template slot="head">
          <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:fixedintegral:save')" @click="openAdd()">新增配置
          </el-button>
          <el-button size="small" type="primary" icon="el-icon-download">导入</el-button>
          <div class="hint">
            <div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
            <div>
              <el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:fixedintegral:delete')" :disabled="selectArr.length<=0" @click="delRow()">删除</el-button>
            </div>
          </div>
        </template>
        <el-table slot="body" border :data="list" @selection-change="getSelect">
          <el-table-column align="center" type="selection" width="50" />
          <el-table-column align="center" prop="typeId" label="类型">
            <template slot-scope="{row}">
              <div v-for="(v,i) in typeList" :key="'typeList'+i" v-if="v.id==row.typeId">{{v.name}}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" label="名称" />
          <el-table-column align="center" prop="score" label="固定得分" />
          <el-table-column align="center" prop="realName" label="创建人" />
          <el-table-column align="center" prop="createDate" label="创建时间" />
          <el-table-column align="center" label="操作" width="300">
            <template slot-scope="{row}">
              <el-button type="text" size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:fixedintegral:update')" @click="openAdd(row)">编辑
              </el-button>
              <el-button type="text" class="c-danger-imp" size="mini" v-if="isAuth('platform-config:fixedintegral:delete')" icon="el-icon-delete" @click="delRow(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" @change="getList('reset')" />
      </jf-layout>
      <Add :show.sync="show" :list="typeList" :id="id" />
      <AddType :show.sync="typeShow" :data="activeData" />
    </div>
  </div>
</template>
<script>
import Add from "./add";
import AddType from "./addType";
export default {
  components: {
    Add,
    AddType,
  },
  data() {
    return {
      show: false,
      typeShow: false,
      pageParam: {
        page: 1,
        limit: 10,
      },
      id: null,
      total: 0,
      typeList: [],
      active: "全部积分类型",
      activeData: {},
      selectArr: [],
      list: [],
    };
  },
  created() {
    this.getTypeList();
    this.getList();
  },
  methods: {
    // 获取固定积分类型列表
    async getTypeList() {
      let res = await this.$get("/platform-config/fixedintegraltype/list");
      if (res && res.code == 0) {
        this.typeList = res.data;
      }
    },
    // 获取 table 列表
    async getList() {
      let res = await this.$get(
        "/platform-config/fixedintegral/page",
        this.pageParam
      );
      if (res && res.code == 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
    // 打开新增类型弹窗
    openType(row) {
      this.activeData = row;
      this.typeShow = true;
    },
    openAdd(row) {
      this.id = row ? row.id : null;
      this.show = true;
    },
    // 选择类型
    activeType(id) {
      this.active = id;
      this.pageParam.typeId = id != -1 ? id : null;
      this.getList();
    },
    // 选择table
    getSelect(e) {
      this.selectArr = e;
    },
    // 删除类型
    delType(row) {
      this.$confirm("确定执行删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.tableLoading = true;
          let res = await this.$del(
            `/platform-config/fixedintegraltype/${row.id}`
          );
          this.tableLoading = false;
          if (res && res.code == 0) {
            this.$message.success("删除成功！");
            this.getTypeList();
          }
        })
        .catch(() => {});
    },
    // 删除 table
    delRow(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      } else {
        this.selectArr.map((v) => {
          ids.push(v.id);
        });
      }
      this.$confirm("确定执行删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.tableLoading = true;
          let res = await this.$del("/platform-config/fixedintegral", ids);
          this.tableLoading = false;
          if (res && res.code == 0) {
            this.$message.success("删除成功！");
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.warp {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  display: flex;

  .left {
    width: 300px;
    border-right: 1px solid #eee;
    padding: 20px;

    .title {
      margin: 0 0 0 0;
      font-size: 18px;
    }

    > ul {
      list-style: none;
      padding: 20px 0 0 0px;

      li {
        padding-left: 15px;
        line-height: 36px;
        border-bottom: 1px solid #eee;

        &:hover {
          cursor: pointer;
        }

        &.active {
          background: rgb(223, 239, 255);
        }

        span {
          float: right;

          i {
            margin: 0 3px;

            &:hover {
              opacity: 0.7;
            }
          }

          .el-icon-edit {
            color: blue;
          }

          .el-icon-delete {
            color: red;
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    padding: 20px;
  }
}

.hint {
  display: flex;
  justify-content: space-between;
  background: #fff4e3;
  border-radius: 5px;
  padding: 0 17px;
  margin-top: 15px;
}
</style>
