<template>
	<el-dialog v-dialogDrag title="新增固定积分分类及子类" :visible.sync="visible" width="40%" :before-close="close">
		<el-form label-width="100px" ref="form" :model="formParam" :rules="rules">
			<el-form-item label="名称">
				<el-input v-model="formParam.name" maxLength="15"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {},
				btnLoading: false,
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			data:{
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		computed: {
			visible: {
				get() {
					this.formParam=JSON.parse(JSON.stringify(this.data));
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {
			
		},
		mounted() {},
		methods: {
			// 确定提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.btnLoading = true
						if (this.data.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 新增
			async save() {
				this.formParam.pid=this.formParam.pid||0;
				let res = await this.$post('/platform-config/fixedintegraltype', this.formParam);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.$emit('update:show', false);
					this.$parent.getTypeList()
				}
			},
			// 修改
			async update() {
				let res = await this.$put('/platform-config/fixedintegraltype', this.formParam);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("修改成功！");
					this.$emit('update:show', false);
					this.$parent.getTypeList()
				}
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}
</style>
